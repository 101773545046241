import { Controller } from "@hotwired/stimulus";
import { I18n } from "i18n-js";
import fr from "../src/translations/fr.json";
import en from "../src/translations/en.json";

// Connects to data-controller="countdown"
export default class extends Controller {
  static values = { targetTime: String }

  connect() {
    this.startCountdown();
  }

  startCountdown() {
    const targetDate = new Date(this.targetTimeValue);
    
    if (isNaN(targetDate)) return;

    this.updateCountdown(targetDate); // Initial update
    this.interval = setInterval(() => {
      this.updateCountdown(targetDate);
    }, 1000);
  }

  updateCountdown(targetDate) {
    const i18n = new I18n({
      fr,
      en,
    });
    i18n.locale = $('body').data('locale');
    const now = new Date();
    const timeDiff = targetDate - now;

    const totalSeconds = Math.floor(timeDiff / 1000); // Convert to seconds

    if (totalSeconds == 299) {
      this.element.innerHTML = i18n.t("event_started");
      clearInterval(this.interval);
      window.location.reload();
      return;
    } else if (totalSeconds == -1) {
      this.element.innerHTML = i18n.t("event_started");
      clearInterval(this.interval);
      window.location.reload();
      return;
    }

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    const seconds = Math.floor((timeDiff / (1000)) % 60);

    let countdownText = "";

    if (days > 0) {
      countdownText += `${days} ${i18n.t("days")}`;
    }

    if (hours > 0 || days > 0) {
      countdownText += (days > 0 ? " - " : "") + `${hours}H`;
    }

    countdownText += (hours > 0 || days > 0 ? " - " : "") + `${minutes}M - ${seconds}S`;

    this.element.innerHTML = countdownText;
  }

  disconnect() {
    clearInterval(this.interval);
  }
}
