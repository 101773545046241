import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="load-more-referrals"
export default class extends Controller {
  static targets = ["seeMoreBtn"];
  static values = { url: String, totalCount: Number };

  connect() {
    this.page = 2; // Start from page 2 for loading more after the initial 3 referrals
  }

  loadMore() {

    if (!this.hasSeeMoreBtnTarget) return;

    const url = `${this.urlValue}?page=${this.page}`;
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'html',
      success: (response) => {
        this.page++;

        // Append new referrals to the list
        $("#referrals-list").append(response);

        // Use setTimeout or requestAnimationFrame to wait until the DOM is updated
        setTimeout(() => {
          // Now count the loaded referrals
          const loadedCount = document.querySelectorAll("#referrals-list > div").length;

          // If all referrals are loaded, hide the "See More" button
          if (loadedCount >= this.totalCountValue) {
            this.seeMoreBtnTarget.remove();
          }
        }, 50); // Set a very short delay to ensure DOM is updated
      },
      error: function(xhr, status, error) {
        console.error("Error loading more referrals:", error);
      }
    });
  }
}

