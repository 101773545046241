import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="read-more"
export default class extends Controller {
  static targets = ["shortText", "longText", "textTruncateByLine", "moreButton", "lessButton"]

  connect() {
    if (this.hasShortTextTarget) {
      this.showLess()
    }
    else if (this.hasTextTruncateByLineTarget) {
      this.checkContentHeight()
    }
  }

  showMore() {
    this.shortTextTarget.hidden = true
    this.moreButtonTarget.hidden = true
    this.longTextTarget.hidden = false
    this.lessButtonTarget.hidden = false
  }

  showLess() {
    this.shortTextTarget.hidden = false
    if (this.hasMoreButtonTarget) {
      this.moreButtonTarget.hidden = false
    }
    this.longTextTarget.hidden = true
    this.lessButtonTarget.hidden = true
  }

  checkContentHeight() {
    // Check if content exceeds 21 lines
    const lineHeight = parseFloat(getComputedStyle(this.textTruncateByLineTarget).lineHeight)
    const maxHeight = lineHeight * 21 // 21 lines max height
    if (this.textTruncateByLineTarget.scrollHeight > maxHeight) {
      this.moreButtonTarget.hidden = false // Show "Read More"
    } else {
      this.moreButtonTarget.hidden = true // Hide "Read More" if not needed
    }
  }

  showMoreTruncateByLine() {
    this.textTruncateByLineTarget.classList.remove("truncate-text")
    this.moreButtonTarget.hidden = true
    this.lessButtonTarget.classList.remove("d-none")
  }

  showLessTruncateByLine() {
    this.textTruncateByLineTarget.classList.add("truncate-text")
    this.moreButtonTarget.hidden = false
    this.lessButtonTarget.classList.add("d-none")
  }
}
