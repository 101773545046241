import { Controller } from "@hotwired/stimulus"
import bulmaCalendar from '../src/application/vendors/bulma-calendar'
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import allLocales from '@fullcalendar/core/locales-all';
import { I18n } from "i18n-js";
import fr from "../src/translations/fr.json";
import en from "../src/translations/en.json";

// Connects to data-controller="meeting-full-calendar"
export default class extends Controller {
  static targets = [ "clientCalendar", "scheduledTimeField", "scheduledDurationField", "getScheduledDateField", "locationIDField", "displayScheduledDateField", "displayScheduledTimeField", "meetingTypesField", "inPersonMeetingType", "onlineMeetingType", "telephoneMeetingType", "phoneNumberTypeField", "otherPhoneNumberField", "videoMeetingExternalUrlField", "locationOverviewField", "meetingTypePhoneField", "meetingAddressFields", "clientPhoneField", "inputClientPhoneField" ]
  static values = { hiddenDays: String, minDelayToBook: String, futureDays: String, locale: String, relationshipId: String, readonly: Boolean, scheduledSessionId: String }

  connect() {
    if (this.hasClientCalendarTarget) {
      const i18n = new I18n({
        fr,
        en,
      });
      i18n.locale = $('body').data('locale');
      // Client's calendar
      var calendar = this.clientCalendarTarget;

      var hiddenDays = this.hiddenDaysValue;
      if (hiddenDays !== '') {
        if ($.type(hiddenDays) == 'string') {
          // got a list of numbers
          hiddenDays = hiddenDays.split(",").map(Number);
        } else {
          // got a single number
          hiddenDays = [hiddenDays];
        }
      } else {
        hiddenDays = [];
      }

      var defaultLocale = this.localeValue;
      if (defaultLocale === 'en') {
        defaultLocale = 'en';
        this.paramsLocale = 'en';
      }
      else {
        defaultLocale = 'fr-ca';
        this.paramsLocale = 'fr';
      }

      this.relationshipId = this.relationshipIdValue;
      if (this.relationshipId == null) this.relationshipId = '';
      
      var eventsPath = "/" + this.paramsLocale + "/clients/scheduled_sessions/availabilities_without_session.json?relationship_id="+this.relationshipId;
      
      var calendar = new Calendar(this.clientCalendarTarget, {
        timezone: 'America/Montreal',
        plugins: [ dayGridPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'prev title next', // All elements on the left
          center: '',              // Center is empty
          right: ''
        },
        locales: allLocales,
        locale: defaultLocale,
        hiddenDays: hiddenDays,
        relationshipId: this.relationshipId,
        paramsLocale: this.paramsLocale,
        dayMaxEventRows: true,
        eventColor: '#3273DC',
        events: eventsPath,
        editable: true,
        selectable: true,
        dateClick: this.dateClick.bind(this), // Bind the dateClick method to this controller
        eventContent: function () {
          return { html: '' }; // Suppress individual event rendering
        },
        eventSourceSuccess: function (content, xhr) {
          const events = content;

          // Recalculate event counts for all day cells
          const dayCells = document.querySelectorAll('.fc-daygrid-day');
          dayCells.forEach(cell => {
            const dateStr = cell.getAttribute('data-date'); // Get the date of the cell
            // Safely filter events for the current day
            const eventCount = events.filter(event => {
              const eventDate = event.start instanceof Date
                ? event.start.toISOString().split('T')[0] // Convert Date object to 'YYYY-MM-DD'
                : event.start.split('T')[0]; // Handle string case
              return eventDate === dateStr;
            }).length;

            const eventWrapper = cell.querySelector('.fc-daygrid-day-events');
            if (eventWrapper) {
              eventWrapper.innerHTML = ''; // Clear default content
              if (eventCount > 0) {
                const indicator = document.createElement('div');
                indicator.className = 'custom-event-indicator';
                indicator.innerHTML = ``;
                eventWrapper.appendChild(indicator);
              }
            }

            // Remove the extra bottom rows (if any)
            const moreLinkWrapper = cell.querySelector('.fc-daygrid-day-bottom');
            if (moreLinkWrapper) {
              moreLinkWrapper.remove();
            }
          });
        },
      });

      calendar.render();
    }
    // Attach event listener to radio buttons
    this.element.addEventListener("change", (event) => {
      if (event.target.matches(".available-slot-radio")) {
        this.hideAndUnCheckedFields();
        this.updateHiddenFields(event.target);
      } else if (event.target.matches(".meeting-type")) {
        this.updateHiddenMeetingtypesFields(event.target);
      }
    });

    // Call the logic for pre-checked radio buttons on page load
    this.initializeCheckedRadios();
  }

  dateClick(info) {
    this.hideAndUnCheckedFields();
    // Get the clicked date and the current date
    const clickedDate = info.dateStr;

    $.ajax({
      url: "/" + this.paramsLocale + "/clients/coachings/get_availabile_slots",
      type: 'GET',
      dataType: "html",
       data: {
        relationship_id: this.relationshipId,
        scheduled_session_id: this.scheduledSessionId,
        start: clickedDate,
        end: clickedDate
      },
      success: (response) => {
        // Update the content of the div
        $('#available-slots-section').html(response);
        if (this.hasDisplayScheduledDateFieldTarget) {
          this.displayScheduledDateFieldTarget.textContent = `${this.getScheduledDateFieldTarget.textContent} :`;
        }
      },
      error: function(xhr, status, error) {
        console.error('Error fetching available slots:', error);
      }
    });
  };

  initializeCheckedRadios() {
    // Find all pre-checked radio buttons and trigger the appropriate functions
    const checkedAvailableSlot = this.element.querySelector(".available-slot-radio:checked");
    if (checkedAvailableSlot) {
      // this.hideAndUnCheckedFields();
      this.updateHiddenFields(checkedAvailableSlot);
    }

    const checkedMeetingType = this.element.querySelector(".meeting-type:checked");
    if (checkedMeetingType) {
      this.updateHiddenMeetingtypesFields(checkedMeetingType);
    }
  }

  hideAndUnCheckedFields() {
    this.meetingTypesFieldTarget.classList.add('hidden');
    this.meetingAddressFieldsTarget.classList.add('hidden');
    this.locationIDFieldTarget.value = '';
    this.phoneNumberTypeFieldTarget.value = '';
    this.otherPhoneNumberFieldTarget.value = '';
    this.videoMeetingExternalUrlFieldTarget.value = '';
    this.displayScheduledTimeFieldTarget.textContent = '';
    this.locationOverviewFieldTarget.textContent = '';
    this.meetingTypesFieldTarget.querySelectorAll("input[type='radio']").forEach(radio => {
      radio.checked = false;
      radio.parentElement.classList.add('hidden');
    });
    if (this.meetingTypePhoneFieldTarget.hasAttribute("data-client-number")) {
      this.meetingTypePhoneFieldTarget.removeAttribute("data-client-number");
    }
  };

  updateHiddenFields(radioButton) {
    if (radioButton.dataset.locationMeeting === 'true' || radioButton.dataset.phoneMeeting === 'true' || radioButton.dataset.videoMeeting === 'true') {
      this.meetingTypesFieldTarget.classList.remove('hidden');
      if (radioButton.dataset.locationMeeting === 'true') {
        this.inPersonMeetingTypeTarget.classList.remove('hidden');
        if(radioButton.dataset.locationId != '') {
          this.locationIDFieldTarget.value = radioButton.dataset.locationId;
        }
      }
      if (radioButton.dataset.phoneMeeting === 'true') {
        this.telephoneMeetingTypeTarget.classList.remove('hidden');
        this.phoneNumberTypeFieldTarget.value = radioButton.dataset.phoneType;
        this.meetingTypePhoneFieldTarget.setAttribute("data-phone-type", radioButton.dataset.phoneType);
        this.meetingTypePhoneFieldTarget.setAttribute("data-client-number", radioButton.dataset.clientNumber);
        if(radioButton.dataset.phoneType === 'other') {
          this.otherPhoneNumberFieldTarget.value = radioButton.dataset.otherPhoneNumber;
        }
      }
      if (radioButton.dataset.videoMeeting === 'true') {
        this.onlineMeetingTypeTarget.classList.remove('hidden');
        if(radioButton.dataset.videoMeetingExternalUrl != '') {
          this.videoMeetingExternalUrlFieldTarget.value = radioButton.dataset.videoMeetingExternalUrl;
        }
      }
    }
    if (this.hasScheduledTimeFieldTarget) {
      this.scheduledTimeFieldTarget.value = radioButton.dataset.scheduledForTime;
    }
    if (this.hasScheduledDurationFieldTarget) {
      this.scheduledDurationFieldTarget.value = radioButton.dataset.scheduledForDuration;
    }
    if (this.hasDisplayScheduledDateFieldTarget) {
      this.displayScheduledDateFieldTarget.textContent = `${radioButton.dataset.loalsSlotsDay} :`;
    }
    if (this.hasDisplayScheduledTimeFieldTarget) {
      this.displayScheduledTimeFieldTarget.textContent = radioButton.dataset.timeSlot;
    }
    if (this.hasLocationOverviewFieldTarget) {
      this.locationOverviewFieldTarget.textContent = radioButton.dataset.locationOverview;
    }
  };
  updateHiddenMeetingtypesFields(radioButton) {
    this.meetingAddressFieldsTarget.classList.add('hidden');
    this.locationOverviewFieldTarget.parentElement.classList.add('hidden');
    this.clientPhoneFieldTarget.parentElement.classList.add('hidden');
    this.clientPhoneFieldTarget.textContent = '';
    this.inputClientPhoneFieldTarget.classList.add('hidden');

    if(radioButton.value === 'physical' || radioButton.value === 'phone') {
      this.meetingAddressFieldsTarget.classList.remove('hidden');
      if(radioButton.value === 'physical') {
        this.locationOverviewFieldTarget.parentElement.classList.remove('hidden');
      } else if (radioButton.value === 'phone') {
        if (radioButton.dataset.phoneType !== 'client' || (radioButton.dataset.clientNumber !== undefined && radioButton.dataset.clientNumber !== null && radioButton.dataset.clientNumber.trim() !== "")) {
          this.clientPhoneFieldTarget.parentElement.classList.remove('hidden');
          this.clientPhoneFieldTarget.textContent = radioButton.dataset.clientNumber;
        }
        else {
          this.inputClientPhoneFieldTarget.classList.remove('hidden');
        }
      }
    }
  }
}
