document.addEventListener('turbo:load', function () {
  var inputLists = document.querySelectorAll(".js-textAreaInputList");

  if (inputLists.length > 0) {
    inputLists.forEach(function (inputList) {
      inputList.addEventListener("click", wasBtnClicked);
      var inputListContainer = inputList.querySelector(".js-textInputList");
      updateButtonVisibility(inputListContainer);
    });
  }

  function wasBtnClicked(e) {
    if (e.target.classList.contains("js-inputlist-btn") || e.target.parentNode.classList.contains("js-inputlist-btn")) {
      var btn = e.target.closest(".js-inputlist-btn");
      var parentList = btn.closest(".js-textAreaInputList");
      var inputListContainer = parentList.querySelector(".js-textInputList"); // Find the `.js-inputlist` inside parent

      if (!btn.classList.contains("js-inputlist-removebtn")) {
        // Add a new item inside `.js-inputlist`
        parentList.dataset.inputlistCount = parseInt(parentList.dataset.inputlistCount, 10) + 1;
        var templateHTML = _template(parentList.dataset.inputlistName, parentList.dataset.inputlistCount);

        var tempDiv = document.createElement("div");
        tempDiv.innerHTML = templateHTML;
        var newNode = tempDiv.firstElementChild;

        inputListContainer.appendChild(newNode); // Append inside `.js-inputlist`
      } else {
        // Remove item
        var item = btn.closest(".u_inputList-item");
        if (item) {
          item.remove();
        }
      }

      updateButtonVisibility(inputListContainer);
    }
  }

  function updateButtonVisibility(list) {
    var items = list.querySelectorAll('.u_inputList-item');
    var buttons = list.querySelectorAll('.js-inputlist-removebtn');

    if (items.length === 1) {
      buttons.forEach(btn => btn.style.display = "none");
    } else {
      buttons.forEach(btn => btn.style.display = "inline-block");
    }
  }

  function _template(name, id) {
    return `
      <div class="u_inputList-item u_inputList-itemText">
        <textarea class="u_textArea mb-0" name="${name}[${id}][name]"></textarea>
        <button type="button" class='u_buttonCircle u_buttonCircleRemove js-inputlist-btn js-inputlist-removebtn'>
          <span class="is-size-4 has-text-white">-</span>
        </button>
      </div>`;
  }
});
