import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="context-menu"
export default class extends Controller {
  static targets = ["area", "btn", "menu"];

  connect() {
    this.btnTargets.forEach((btn, index) => {
      btn.addEventListener("click", (e) => this.toggleMenu(index));
    });

    this.menuTargets.forEach((menu) => {
      menu.addEventListener("mouseleave", () => this.hideMenu(menu));
    });

    this.areaTargets.forEach((area) => {
      area.addEventListener("mouseleave", () => this.hideMenu(area.querySelector(".js_goalContextMenu")));
    });
  }

  toggleMenu(index) {
    const menu = this.menuTargets[index];
    if (menu.style.display === "none" || menu.style.display === "") {
      menu.style.display = "block";
    } else {
      menu.style.display = "none";
    }
  }

  hideMenu(menu) {
    if (menu) menu.style.display = "none";
  }
}
